import { Login, LoginManager } from './login'
import { LOGIN_COOKIE, API_HOST, ENV } from '../config'

var qqConnectDefaultOpts: ParamsConfig = {
  appid: '',
  uri: API_HOST + '/qqconnect_login',
};

interface qqLoginManager extends Login {
  // isQQ(): boolean
  toAuth(): void | string
}

class QQConnectLogin extends LoginManager implements qqLoginManager {
  constructor(config: ParamsConfig, game: String) {
    super(config, game)
  }
  /**
 * 检查是平台否为qq
 */
  // isQQ() {
  //   var sUserAgent = this.getUserAgent().toLowerCase();
  //   var isqq = / qq/i.test(sUserAgent);
  //   return isqq
  // }

  /**
   * 登录
   */
  toAuth() {
    var me = this;
    var opts: ParamsConfig = {
      ...qqConnectDefaultOpts,
      ...me.config
    };
    var queryParams: string[] = [
      'appid=' + opts.appid,
      'rflag=appt',
      'return_uri=' + encodeURIComponent(window.location.href),
      'game=' + me.game
    ];
    opts.uri = encodeURIComponent(opts.uri + '?' + queryParams.join('&'));
    var querys: string = `response_type=code&client_id=${opts.appid}&redirect_uri=${opts.uri}&scope=get_user_info`;

    const href: string = 'https://graph.qq.com/oauth2.0/authorize?' + querys
    window.location.href = href
  }
}


export default QQConnectLogin