import getUrlParams from './utils/getUrlParams'


function isProd(): boolean {
  if (getUrlParams('env') === 'develop') {
    return false
  }
  return true
}

type environmentType = 'node' | 'production' | 'develop' | ''
let environment: environmentType = ''

environment = isProd() ? 'production' : 'develop';


export const LOGIN_COOKIE: string = 'slug';
// export const API_HOST = 'https://app.ingame.qq.com/testlogin/gingame/auth';
export const API_HOST = 'https://app.ingame.qq.com/gingame/auth';
export const WX_URI = 'https://gw.gzh.qq.com/gingame/auth/wxcomponent_login';
// export const WX_URI = 'https://gw.gzh.qq.com/testlogin/gingame/auth/wxcomponent_login'
export const ENV = environment;