
import WxmLogin from './platform/wxm'
import QQLogin from './platform/qqconnect'
import WxpcLogin from './platform/wxpc'
import { ENV, LOGIN_COOKIE } from './config'
import cookie from 'js-cookie';

let registerLogin = {
  wxm: WxmLogin,
  qq: QQLogin,
  wxpc: WxpcLogin,
};
let configs = {};
let allowLogin = []; // 允许登陆平台的队列
let game = ''
let curLogin = ''
let curPlatform = function () {
  const na = navigator.userAgent
  if (/MicroMessenger/i.test(na)) {
    return 'wxm'
  }
  if (/ qq/i.test(na.toLowerCase()) && !(/msdk/i.test(na)) && !(/ QQBrowser/i.test(na))) {
    return 'qq'
  }
  return ''
}


interface inlogin {
  game?: string,
  type?: string,
  config?: object
}

let inlogin: inlogin = {}


/**
 * 判断平台是否为有效平台
 * @param platform 
 */
function validPlatform(platform: platformType): void | boolean {
  if (!registerLogin[platform]) {
    throw 'invalid login platform: ' + platform;
  }
  if (!allowLogin.includes(platform)) {
    throw 'no configuration：' + platform;
  }
}


/**
 * 实例化登录
 * @param platform 登录平台
 */
function newLogin(platform: platformType) {
  validPlatform(platform)
  let LoginClass = registerLogin[platform]
  return new LoginClass(configs[platform] || {}, game);
}


/**
 * 根据平台参数，对应处理事件
 * @param platform 登录平台
 * @param event 需要处理的事件
 * @param option 其他参数
 */
function handleEvent(platform: platformType, event: string, ...option) {

  platform = platform || slugLogin.curPlatform || curLogin
  if (platform) {
    const appid = configs[platform].appid
    if (!inlogin || !(inlogin.type === platform)) {
      inlogin = newLogin(platform)
    }
    return inlogin[event](appid, ...option)
  }


  if (event === 'checkLogin' || event === 'isLogin') {
    return false
  }
  console.warn(`Unrecognized ${event} platform`) // 没有检查到平台
}

/**
 * 去登录
 * @param login 登录实例
 */
function _login(login): void {
  if (!login.isLogin()) {
    login.toAuth();
  }
}

// interface params {
//   game: string;
//   platform: object;
// }

interface params {
  wxm: ParamsConfig;
  wxpc: ParamsConfig;
  qq: ParamsConfig;
}

interface slugLogin {
  config(params): void
  login(platform?: platformType): void
  isLogin(platform?: platformType): boolean
  logout(platform?: platformType): void
  getUserInfo(platform?: platformType): Promise<any>
  checkLogin(options?: any): Promise<any>
  curPlatform: any
}


var slugLogin: slugLogin = {
  /**
   * 平台配置
   * @param params 配置参数 
   */
  config: function (params) {
    game = params.game
    if (!game) throw 'Please select a game'
    const { platform: platConfig } = params
    allowLogin = Object.keys(platConfig) as Array<keyof typeof platConfig>
    allowLogin.forEach(function (platform) {
      validPlatform(platform)
      configs[platform] = {
        ...platConfig[platform],
        type: platform
      }
    })
    curLogin = allowLogin.find(platform => !!cookie.get(LOGIN_COOKIE + '_exp_' + configs[platform].appid))
  },
  /**
   * 当前平台
   */
  curPlatform: curPlatform(),

  /**
   * 登录
   * @param platform 平台 {qq: {}, wxm: {}}
   */
  login: function (platform) {
    platform = platform || slugLogin.curPlatform
    if (platform) {
      const login = newLogin(platform)
      _login(login)
    } else {
      console.warn(`Unrecognized platform`) // 没有检查到平台
    }
  },

  /**
   * 判断是否已经登录
   * @return {Boolean} [description]
   */
  isLogin: function (platform) {
    return handleEvent(platform, 'isLogin')
  },

  /**
   * 登出
   * @param platform 
   */
  logout: function (platform) {
    return handleEvent(platform, 'logout')
  },

  /**
   * 获取用户信息
   * @param platform 
   */
  getUserInfo: function (platform) {
    if (!this.isLogin(platform)) {
      throw 'Please login first';
    }
    return handleEvent(platform, 'getUserInfo')
  },

  /**
   * 检查登录
   * @param options {appid, game, game_convert}
   */
  checkLogin: function (options = {}) {
    let platform, game;
    if (typeof (options) == 'string') {
      platform = options
    } else {
      platform = options.platform
      game = options.game
    }

    // 前端没有登陆态
    if (!this.isLogin(platform)) {
      return new Promise((resolve, reject) => {
        resolve({
          isLogin: false
        });
      })
    }
    return handleEvent(platform, 'checkLogin', game)
  }
};

// export default slugLogin;

window['slugLogin'] = slugLogin;
