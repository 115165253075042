import { Login, LoginManager } from './login'
import { API_HOST, ENV } from '../config'

var wxpcDefaultOpts: ParamsConfig = {
  appid: '',
  uri: API_HOST + '/wx_login'
};

interface WxpcLoginManager extends Login {
  toAuth(): void | string
}
class WxpcLogin extends LoginManager implements WxpcLoginManager {
  constructor(config: ParamsConfig, game: String) {
    super(config, game)
  }

  /**
   * 登录
   */
  toAuth() {
    var me = this;
    var opts: ParamsConfig = {
      ...wxpcDefaultOpts,
      ...me.config
    };

    var queryParams: string[] = [
      'appid=' + opts.appid,
      'return_uri=' + encodeURIComponent(window.location.href),
      'game=' + me.game
    ];

    opts.uri = encodeURIComponent(opts.uri + '?' + queryParams.join('&'));

    var querys: string = `appid=${opts.appid}&redirect_uri=${opts.uri}&response_type=code&scope=snsapi_login&state=&#wechat_redirect`;

    window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?' + querys
  }
}

export default WxpcLogin