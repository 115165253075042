import { API_HOST } from '../config'

/**
 * @param  {Object} opts
 *         path,method,params,data
 * @return {Promise}
 */
function makeRequest(opts) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()
    let data = opts.data
    let params = opts.params
    let url

    if (opts.path.startsWith('http')) {
      url = opts.path
    } else {
      url = API_HOST + opts.path
    }

    if (params && typeof params === 'object') {
      const separator = url.indexOf('?') === -1 ? '?' : '&'
      params = separator + httpQueryBuild(params)
      url += params
    }

    if (opts.withCredentials) {
      xhr.withCredentials = true
    }

    xhr.open(opts.method, url)
    xhr.responseType = 'json'

    if (opts.headers) {
      Object.keys(opts.headers).forEach(function (key) {
        xhr.setRequestHeader(key, opts.headers[key])
      })
    }

    if (data && typeof data === 'object') {
      data = JSON.stringify(data)
    }

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300 || xhr.status == 304) {
        resolve(xhr.response)
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
          response: xhr.response
        })
      }
    }

    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
        response: xhr.response
      })
    }

    xhr.send(data)
  })
}

function httpQueryBuild(data) {
  return Object.keys(data).map(function (key) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
  }).join('&')
}

export default makeRequest
