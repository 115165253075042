import cookie from 'js-cookie';
import api from '../api/index'
import { LOGIN_COOKIE, API_HOST, ENV } from '../config'
const { userAgent } = navigator;


interface Login {
  getUserInfo(appid: string): Promise<any>;
  checkLogin(appid: string, game?: string): Promise<any>;
}

class LoginManager implements Login {
  constructor(config: ParamsConfig, game: String) { // 该类只允许被继承
    this.config = config;
    this.type = config.type;
    this.game = game;
  }

  protected config: ParamsConfig
  protected type: platformType
  protected game: String
  private hasCollect = false
  /**
   * 获取用户信息
   * @param appid 
   */
  getUserInfo(appid) {
    return api.getUserInfo({
      appid,
      login_type: 'slug',
      game: this.game
    }).then(res => res.data).catch((err) => {
      console.log(err)
      alert('查询用户信息失败')
    })
  }
  /**
   * 获取当前实例cookie
   */
  getCookieKey() {
    return LOGIN_COOKIE + '_exp_' + this.config.appid;
  }
  /**
 * 登出
 */
  isLogin() {
    if (!this.config.appid) {
      throw new Error('login need appid');
    }
    return !!cookie.get(this.getCookieKey());
  }
  logout() {
    cookie.remove(this.getCookieKey(), { domain: '.qq.com' });
    cookie.remove(LOGIN_COOKIE + '_openid_' + this.config.appid, { domain: '.qq.com' });
    this.hasCollect = false
    // 清除上报相关的存储
    sessionStorage.removeItem('tokenParams');
    cookie.remove('tokenParams', { domain: '.qq.com' });
  }
  /**
   * 检查登录
   * @param appid 
   * @param game 需要转换游戏openid时需要
   * @param gameConvert 转换目标app名称，当需要转换游戏openid时，与game相同即可，如果是转非游戏openid，填对应的appname。
   */
  checkLogin(appid, game = this.game) {
    const that = this
    return api.checkLogin({
      appid,
      game,
      game_convert: game,
      login_type: 'slug'
    }).then(res => {
      if (res.code === 0) {
        // 上报组件埋点
        if (!/msdk/i.test(userAgent)) {
          const { openid, game_openid: gameopenid, game_appid: gameappid } = res.data
          const str = `?appid=${appid}&openid=${openid}&game_openid=${gameopenid}&game_appid=${gameappid}`
          sessionStorage.setItem('tokenParams', str)
          if (typeof (pgvMain) == 'function' && !this.hasCollect && __PTTDmp) {
            __PTTDmp.searchObj = __PTTDmp.parseQuery(str)
            PTTSendClick('datareport', 'login', 'gameinfo')
            this.hasCollect = true
          }
        }
        return {
          isLogin: true,
          ...res.data
        }
      } else if (res.code === 67250001 || res.code === 2 || res.code === 40030) {
        that.logout()
        return { isLogin: false }
      } else {
        that.logout()
        throw res.msg
      }
      // 2 invalid session_id 没有登录态
      // 67250001 session not found 过期还是错误？
      // 67250008 convert target appid not found
      // 40030
    })
      .catch((err) => {
        throw err
      })
  }
}

export { LoginManager, Login }
