import { Login, LoginManager } from './login'
import { LOGIN_COOKIE, API_HOST, ENV, WX_URI } from '../config'

var wxDefaultOpts: ParamsConfig = {
  appid: '',
  scope: 'snsapi_userinfo', // 默认是用户信息授权, base or userinfo
  uri: WX_URI,
  // uri: 'https://gw.gzh.qq.com/testlogin/gingame/auth/wxcomponent_login'
};

interface WxLoginManager extends Login {
  // isWx(): boolean
  toAuth(): void | string
}
class WxLogin extends LoginManager implements WxLoginManager {
  constructor(config: ParamsConfig, game: String) {
    super(config, game)
  }

  /**
   * 检查是平台否为微信
   */
  // isWx() {
  //   return /MicroMessenger/i.test(this.getUserAgent());
  // }

  /**
   * 登录
   */
  toAuth() {
    var me = this;
    var opts: ParamsConfig = {
      ...wxDefaultOpts,
      ...me.config
    };

    var queryParams: string[] = [
      'appid=' + opts.appid,
      'return_uri=' + encodeURIComponent(window.location.href),
      'component_appid=wx22677efbb8d0f79f',
      'game=' + me.game
    ];

    opts.uri = encodeURIComponent(opts.uri + '?' + queryParams.join('&'));

    // if (opts.isThirdParty) {
    // 	querys = 'appid={appid}&redirect_uri={uri}&response_type=code&scope=snsapi_base&state={state}&component_appid=wx0dea96220faf6cba#wechat_redirect';
    // } else {
    // 	querys = 'appid={appid}&redirect_uri={uri}&response_type=code&scope=snsapi_base&state={state}#wechat_redirect';
    // }

    var querys: string = `appid=${opts.appid}&redirect_uri=${opts.uri}&response_type=code&scope=${opts.scope}&state=&component_appid=wx22677efbb8d0f79f#wechat_redirect`;

    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?' + querys
  }
}

export default WxLogin