import makeRequest from './http'
interface api {
  checkLogin(params): Promise<any>
  getUserInfo(params): Promise<any>
}

const api: api = {
  /**
   * 检查登录接口
   * @param params {appid, game?, game_convert?, login_type}
   */
  checkLogin(params) {
    return makeRequest({
      method: 'get',
      path: '/check_login',
      withCredentials: true,
      params
    })
  },

  /**
   * 获取用户信息接口
   * @param params {appid, login_type}
   */
  getUserInfo(params) {
    return makeRequest({
      method: 'get',
      path: '/get_user_info',
      withCredentials: true,
      params
    })
  }
}

export default api